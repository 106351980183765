/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

var scrollInit = true;

// Scrolled down state (for menu display)
jQuery(window).scroll(function(event) {
    if (jQuery(window).scrollTop() > 20) {
        if (scrollInit) {
            scrollInit = false;
            jQuery('body').addClass('scrolled');
        }
    } else {
        if (!scrollInit) {
            scrollInit = true;
            jQuery('body').removeClass('scrolled');
        }
    }
});

(function($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function() {

                // Mobile menu toggle
                $('#nav-toggle').on('click', function() {
                    $('.nav-primary ul').toggleClass('visible');
                });
                
                // Hero video player
                $('.hero .video-play').on('click', function() {
                    $( this ).toggleClass( 'fa-play-circle' )
                        .toggleClass( 'fa-pause-circle' );
                    toggleVideoPlay( document.getElementById('hero-video') );
                });

            },
            finalize: function() {}
        },

        // Shop page
        'post-type-archive-product': {
            init: function() {

                // Categorize products
                splitProductsByTag();

                // Hide products that don't match the date range (if a date is set)
                today = moment().format('YYYY-MM-DD');
                if (jQuery.QueryString.start || jQuery.QueryString.end) {
                    var start = jQuery.QueryString.start || today; // Default to today
                    var end = jQuery.QueryString.end || '9999-12-31'; // Default to an upper limit
                    // Check date format is valid (doesn't check date itself is valid)
                    if (
                        null !== start.match(/^(\d{4})\-(\d{2})\-(\d{2})$/) &&
                        null !== end.match(/^(\d{4})\-(\d{2})\-(\d{2})$/)
                    ) {
                        filterProductsByDate(start, end);
                    } else {
                        console.log('Invalid date format');
                    }
                }

                // Add notice to product containers that have hidden products
                jQuery('.trips-hidden .show-more-notice').show();

                // If we are filtering on destination and that destination has no trips planned, show a notice
                if (jQuery.QueryString.destination) {
                    jQuery('.ctc-product-tag.no-trips .no-trips-notice').show();
                    // Otherwise just hide the whole destination, no notice
                } else {
                    jQuery('.ctc-product-tag.no-trips.no-trips').hide();
                }

                // Set product classes according to availability
                filterProductsByAvailability(start, end);

            },
            finalize: function() {}
        },

        // Product page
        'single-product': {
            init: function() {

                // Wait for bookable days to appear (added dynamically by plugin)
                ready('td.bookable', function(element) {

                    if (!noBookableDates) {

                        // Loop through days and mark those that fall within the range
                        // of the product's set duration
                        var daysAfterBookable = 0;
                        jQuery('.ui-datepicker-calendar tbody td').each(function(index, day) {

                            // Convert to jQuery object
                            day = jQuery(day);

                            // First day of the event
                            if (day.hasClass('bookable')) {
                                daysAfterBookable++;
                            } else {
                                // Days during the event
                                if (daysAfterBookable > 0) {
                                    day.addClass('during-event');
                                    daysAfterBookable++;
                                }
                                // Last day of the event
                                if (daysAfterBookable >= productDuration) {
                                    day.addClass('during-event last-of-event');
                                    daysAfterBookable = 0;
                                }
                            }
                        });

                    }

                    // Add listener to bookable date
                    //           jQuery( 'td.bookable > a' ).on( 'click', function() {
                    //             // When clicking on the date show persons field and add to cart button
                    //             $( '.wc_bookings_field_persons' ).show();
                    //             $( '.entry-summary button[type=submit]' ).show();
                    //           });
                });
                // Change calendar header (again, wait for header to appear dynamically)
                ready('.wc-bookings-date-picker', function(element) {
                    jQuery('.wc-bookings-date-picker legend span.label').html(local.strAvailableDates);

                    if (noBookableDates) {
                        jQuery('form.cart').addClass('no-bookable-dates');
                    }

                });

                // Add click event to "organize date" section
                const organizeDateHeader = $('.organize-date h1');
                appendFAIcon(organizeDateHeader, 'fa-chevron-circle-right');
                organizeDateHeader.click(function() {
                    jQuery('.organize-date form').slideToggle();
                    organizeDateHeader.find('.fa').toggleClass('open');
                });

                // Set default value of contact form
                $('.contact-new-date-destination').val($('.product-main-title').html());

                // Change WPIS (gallery) plugin popup button text
                // String localized in translation-strings.php
                $('a.wpis-popup').html(local.strBtnShowPhotos);

                var optionsMoreThanOne = '';
                const maxQuantity = productMaxQuantity || local.maxQuantity;
                for (i = 2; i <= maxQuantity; i++) {
                    optionsMoreThanOne += ('<option value="' + i + '">' + i + ' ' + local.strChallengers + '</option>');
                }
                $('.wc_bookings_field_persons input[type=number]').after(
                    '<div class="field-wrapper">' +
                    '<select onchange="bookingQuantity(this.value)">' +
                    '<option value="1">1 ' + local.strChallenger + '</option>' +
                    optionsMoreThanOne +
                    '</select>' +
                    '</div>'

                    // Plus/minus buttons
                    //           '<input type="button" class="minus" onclick="return bookingQuantity(-1)" value="-" />'
                    //           +'<input type="button" class="plus" onclick="return bookingQuantity(1)" value="+" />'

                );

                // Quantity: Set initial display value
                //         jQuery('.variation-qty').each( function() {
                //           jQuery( this ).html( jQuery( this ).parent().find('input.qty').val() );
                //         });
                // Quantity: Update display value
                //         jQuery('input.qty').each( function() {
                //           jQuery( this ).on('change', function() {
                //             jQuery( this ).parent().find( '.variation-qty' ).html( jQuery( this ).val() );
                //           });
                //         });

                // Add icons on single product page
                //         if( jQuery('body').hasClass('single-product') ) {
                //           jQuery( 'td.value' ).append( '<a href="#" onclick="return showCalendar()"><span class="fa fa-calendar"></span></a>' );
                //         }

                // Sticky booking box (DISABLED FOR NOW - don't delete)
                //         const imageContainer = jQuery( '.product .images' );
                //         var summaryBoxInitTop = imageContainer.height();
                //         const body = jQuery('body');
                //         const summaryBox = jQuery('.entry-summary');
                //         const clearance = 80;
                //         const summaryBoxInitMargin = summaryBox.css('margin');
                //
                //         var summaryBoxInitOffset = (summaryBox.offset()).top;
                //         var isMobile = false;
                //
                //         doSticky();
                //
                //         function doSticky() {
                //
                //           if( isMobile )  {
                //             summaryBox.css('top', 0);
                //             summaryBox.css('margin', 0);
                //           }
                //           else if( body.scrollTop() > (summaryBoxInitOffset - clearance) ) {
                //             summaryBox.css('position', 'fixed');
                //             summaryBox.css('top', clearance);
                //             summaryBox.css('margin', 0);
                //           } else {
                //             summaryBox.css('position', 'absolute');
                //             summaryBox.css('top', summaryBoxInitTop);
                //             summaryBox.css('margin', summaryBoxInitMargin);
                //           }
                //         }
                //
                //         jQuery( window ).on( 'scroll', function() {
                //           doSticky();
                //         });
                //
                //         const breakpoint = 768;
                //         jQuery( window ).on( 'resize', function() {
                //           if( !isMobile && jQuery( window ).width() <= breakpoint ) {
                //             isMobile = true;
                //             doSticky();
                //           } else if( jQuery( window ).width() > breakpoint ) {
                //             if( isMobile ) {
                //               isMobile = false;
                //             }
                //             summaryBoxInitTop = imageContainer.height();
                //             doSticky();
                //           }
                //         });

            },
            finalize: function() {}
        },

        // Checkout page
        'woocommerce-checkout': {
            init: function() {

                // Scroll to top
                window.scrollTo(0, 0);

                // Add date picker on checkout page
                $(".datepicker input").datepicker({
                    dateFormat: "yy-mm-dd",
                    changeMonth: true,
                    changeYear: true,
                    showOptions: { direction: "down" },
                    yearRange: "-120:+0"
                })
                // Also set input to readonly (to avoid mobile keyboard appearing)
                .attr( 'readonly', 'readonly' );

                // Add 'is main contact' checkbox to each Challenger to pre-fill billing details field
                jQuery('.checkout-participant-fields p:last-of-type').after(
                    '<p class="form-row form-row-wide">' +
                    '<input type="checkbox" class="set-main-contact" onclick="setMainContact(this)"> ' +
                    local.strIsMainContact + '</p>' // localized in translation-strings.php
                );

                // Uncheck all 'is main contact' checkboxes if billing fields are edited
                jQuery('.woocommerce-billing-fields input').change(function() {
                    jQuery('.set-main-contact').prop('checked', false);
                });

            },
            finalize: function() {}
        },

        // Home page
        'home': {
            init: function() {
                jQuery('.price .amount').each(function() {
                    jQuery(this).appendTo(jQuery(this).parent().parent().find('h3'));
                });

            },
            finalize: function() {
                // JavaScript to be fired on the home page, after the init JS
            }
        }

    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function(func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function() {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.split(/\s+/), function(i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.

function toggleVideoPlay( videoElement ) {
    if( videoElement.paused ) {
        videoElement.play();
    } else {
        videoElement.pause();
    }
}

// Single product booking form
function bookingQuantity(d) {
    const theInput = jQuery('.wc_bookings_field_persons input[type=number]');
    theInput.val(d);
    theInput.trigger('change');
    theInput.trigger('input');

    //   const currentVal = parseInt( theInput.val() );
    //   const newVal = currentVal + d;
    //   if( newVal >= 1 && newVal <= theInput.attr('max') ) {
    //     theInput.val( newVal );
    //     theInput.trigger( 'change' );
    //     theInput.trigger( 'input' );
    //   }
}

function strip(html) {
    var tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
}

// Adds Font Awesome icon
function prependFAIcon(domQuery, symbolClass) {
    jQuery(domQuery).prepend('<span class="fa ' + symbolClass + '"></span>');
}

function appendFAIcon(domQuery, symbolClass) {
    jQuery(domQuery).append('<span class="fa ' + symbolClass + '"></span>');
}

// Shows booking form on mobile
function toggleBookingForm() {
    jQuery('.modal-product-title').slideToggle();
    jQuery('.entry-summary').slideToggle();
    jQuery('body').toggleClass('mobile-modal-open');
    return false;
}

// Shows search form on mobile
function toggleSearchForm() {
    jQuery('body').toggleClass('mobile-modal-open');
    jQuery('.product-search-wrapper').toggleClass('mobile-modal-visible');
    return false;
}

// Open/close destination tag section
function toggleTag(link) {
    jQuery('.' + jQuery(link).attr('data-slug')).toggleClass('closed')
        .find('.product-wrapper').slideToggle();
    return false;
}

// Toggles display of medical info field
function showMedicalInfo() {
    jQuery('.product-medical-info').slideToggle();
    return false;
}

// Copy Challenger info to Billing Address section
function setMainContact(checkbox) {
    if (checkbox.checked) {
        // Get values of this Challenger
        const fieldGroup = jQuery(checkbox).parent().parent();
        const firstName = fieldGroup.find('.participant-name input').val();
        const lastName = fieldGroup.find('.participant-surname input').val();
        const email = fieldGroup.find('.participant-email input').val();
        const phone = fieldGroup.find('.participant-phone input').val();

        // Get billing inputs
        const billingFirstName = jQuery('#billing_first_name');
        const billingLastName = jQuery('#billing_last_name');
        const billingEmail = jQuery('#billing_email');
        const billingPhone = jQuery('#billing_phone');

        // Copy values
        billingFirstName.val(firstName);
        billingLastName.val(lastName);
        billingEmail.val(email);
        billingPhone.val(phone);

        // Uncheck other checkboxes
        jQuery('.set-main-contact').prop('checked', false);
        jQuery(checkbox).prop('checked', true);
    }
}

// Take products generated by WooCommerce and sort them into containers for each tag
function splitProductsByTag() {
    tags.forEach(function(tag) {
        tagContainer = jQuery('.ctc-product-tag.slug-' + tag.slug + ' .product-wrapper');
        jQuery('.product.product_tag-' + tag.slug).appendTo(tagContainer);
        // Hide tag if there are no products
        if (0 == tagContainer.find('.product').length) {
            tagContainer.parent().hide();
        }
    });
}

// Hide products that don't match date range
function filterProductsByDate(start, end) {

    Object.keys(scheduledTrips).forEach(function(productID) {

        // Whether trips are scheduled in the date range
        tripsInRange = scheduledTrips[productID].some(function(thedate) {
            return ((thedate >= start) && (thedate <= end));
        });
        // Whether this product has trips scheduled at all
        hasTrips = scheduledTrips[productID] && scheduledTrips[productID].length > 0;

        if (!tripsInRange || !hasTrips) {
            const product = jQuery('.post-' + productID);
            const productTagContainer = product.parent().parent();

            if (!hasTrips) {
                // No trips scheduled - hide
                product.hide().addClass('hidden no-trips');
            } else if (!tripsInRange) {
                // Trips scheduled but not in this date range - hide and show a notice
                product.hide().addClass('hidden');
                productTagContainer.addClass('trips-hidden');
            }

            // If all products in a tag are hidden then hide the tag as well
            if (productTagContainer.find('.product-wrapper .product').not(".no-trips").length == 0) {
                productTagContainer.addClass('no-trips');
            }
        }
    }); //foreach

}

// Show notice about hidden products
function showHiddenForTag(slug) {
    jQuery('.slug-' + slug + ' .hidden').fadeIn();
    jQuery('.slug-' + slug + ' .show-more-notice').hide();
    return false;
}

// Add class 'sold-out' to products with no spaces left during the specified time
function filterProductsByAvailability(start, end) {
    Object.keys(availability).forEach(function(productID) {
        Object.keys(availability[productID]).forEach(function(date) {
            if (date >= start && date <= end && availability[productID][date] == 0) {
                jQuery('.post-' + productID).addClass('sold-out');
            }
        });
    });

}


// Get query parameters
(function(jQuery) {
    jQuery.QueryString = (function(a) {
        if (a == "") return {};
        var b = {};
        for (var i = 0; i < a.length; ++i) {
            var p = a[i].split('=', 2);
            if (p.length != 2) continue;
            b[p[0]] = decodeURIComponent(p[1].replace(/\+/g, " "));
        }
        return b;
    })(window.location.search.substr(1).split('&'))
})(jQuery);

// Add addons on cart page
function cartAddon(selectID) {

    const select = jQuery(selectID);
    const $form = jQuery('.shop_table.cart').closest('form');
    const $productID = select.attr('data-product-id');
    const $quantity = select.val();

    // Block
    blockCart();

    // Add addons to cart
    const data = {
        'action': 'cart_addon',
        'productID': $productID,
        'quantity': $quantity
    };
    jQuery.ajax({
        type: 'POST',
        data: data,
        url: local.ajaxURL,
        success: function(response) {
            window.location.reload();
            // TODO: display success message?
        },
        error: function(jqXHR, textStatus, errorThrown) {
            // TODO: display error (using WooC functions?)
            alert(textStatus);
            unblock($form);
            unblock($('div.cart_totals'));
        },
        complete: function() {}
    });

    return false;
}

// Block while loading
function blockCart() {
    const $form = jQuery('.shop_table.cart').closest('form');
    block($form);
    block(jQuery('div.cart_totals'));
    block(jQuery('.cross-sells'));
}

/**
 * Check if a node is blocked for processing.
 *
 * @param {JQuery Object} $node
 * @return {bool} True if the DOM Element is UI Blocked, false if not.
 */
var is_blocked = function($node) {
    return $node.is('.processing') || $node.parents('.processing').length;
};

/**
 * Block a node visually for processing.
 *
 * @param {JQuery Object} $node
 */
var block = function($node) {
    if (!is_blocked($node)) {
        $node.addClass('processing').block({
            message: null,
            overlayCSS: {
                background: '#fff',
                opacity: 0.6
            }
        });
    }
};

/**
 * Unblock a node after processing is complete.
 *
 * @param {JQuery Object} $node
 */
var unblock = function($node) {
    $node.removeClass('processing').unblock();
};

// Mutation Observer code
(function(win) {
    'use strict';

    var listeners = [],
        doc = win.document,
        MutationObserver = win.MutationObserver || win.WebKitMutationObserver,
        observer;

    function ready(selector, fn) {
        // Store the selector and callback to be monitored
        listeners.push({
            selector: selector,
            fn: fn
        });
        if (!observer) {
            // Watch for changes in the document
            observer = new MutationObserver(check);
            observer.observe(doc.documentElement, {
                childList: true,
                subtree: true
            });
        }
        // Check if the element is currently in the DOM
        check();
    }

    function check() {
        // Check the DOM for elements matching a stored selector
        for (var i = 0, len = listeners.length, listener, elements; i < len; i++) {
            listener = listeners[i];
            // Query for elements matching the specified selector
            elements = doc.querySelectorAll(listener.selector);
            for (var j = 0, jLen = elements.length, element; j < jLen; j++) {
                element = elements[j];
                // Make sure the callback isn't invoked with the
                // same element more than once
                if (!element.ready) {
                    element.ready = true;
                    // Invoke the callback with the element
                    listener.fn.call(element, element);
                }
            }
        }
    }

    // Expose `ready`
    win.ready = ready;

})(this);